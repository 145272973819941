<template>
  <div class="xz-detail">
    <div class="empty" v-if="isLoading">
      <van-loading type="spinner"/>
    </div>
    <template v-else>
      <template v-if="detail">
        <div class="up">
          <div class="back" @click="back">
            <img :src="require('../assets/img/detail-back.png')" width="100%" height="100%"/>
          </div>
        </div>
        <div class="content">
          <div class="item">
            <span class="f-17">藏品信息</span>
            <span class="tip f-13">{{detail.desc}}</span>
          </div>
          <div class="item">
            <span class="f-15">藏品名称</span>
            <span class="f-15">{{detail.nftname}}</span>
          </div>
          <div class="item">
            <span class="f-15">拥有者</span>
            <span class="f-15">{{detail.personName}}</span>
          </div>
          <div class="item">
            <span class="f-15">创作者</span>
            <span class="f-15">{{detail.author}}</span>
          </div>
          <div class="item">
            <span class="f-15">发行人</span>
            <span class="f-15">{{detail.man}}</span>
          </div>
          <div class="item">
            <span class="f-15">发行时间</span>
            <span class="f-15">{{detail.create_time}}</span>
          </div>
          <div class="item">
            <span class="f-15">区块链信息</span>
            <span class="f-15 copy" @click="copy">{{detail.nftid}}</span>
          </div>
          <van-divider />
          <div class="f-11 white text-center">关注公众号【轻松小镇】，加入社区第一时间获取后续玩法咨询</div>
        </div>
      </template>
      <div v-else class="empty white f-16">
        <back></back>
        你还未获得数字按摩器哦~
      </div>
    </template>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { getNFTDetailApi } from '../api/user'
import moment from 'moment'
import Clipboard from 'clipboard'
import Back from '../components/Back'
import { getWxConfig } from '../../tool'
export default {
  name: 'XZDetail',
  components: { Back },
  data () {
    return {
      isShow: true,
      url: require(this.Qimg + 'detail-video2.mp4'),
      urls: [require(this.Qimg + 'detail-video2.mp4'), require(this.Qimg + 'detail-video.mp4')],
      current: 0,
      detail: null,
      isLoading: true
    }
  },
  computed: {
    ...mapState({
      user: state => state.user
    })
  },
  created () {
    this.getDetail()
    getWxConfig('轻松小镇', '数字勋章藏品详情')
  },
  mounted () {
    document.addEventListener('scroll', (e) => {
      const scrollTop = document.documentElement.scrollTop || document.body.scrollTop
      console.log(scrollTop)
      if (scrollTop > 50) {
        this.isShow = false
      }
    }, false)
  },
  destroyed () {
    document.removeEventListener('scroll', () => {})
  },
  methods: {
    back () {
      if (window.history.length <= 1) {
        this.$router.push({ path: '/' })
        return false
      } else {
        this.$router.go(-1)
      }
    },
    copy () {
      const clipBoard = new Clipboard('.copy', {
        text: () => {
          return this.detail.copyId
        }
      })
      clipBoard.on('success', (e) => {
        console.log(e)
        this.$toast('复制成功')
      })
      clipBoard.on('error', (err) => {
        console.log(err)
        this.$toast.fail('复制失败')
      })
    },
    async getDetail () {
      try {
        const res = await getNFTDetailApi({})
        this.isLoading = false
        if (!res.data.data) return
        const time = moment(new Date(res.data.data[0].create_time * 1000)).format('YYYY-MM-DD')
        const ID = res.data.data[0].nftid
        this.detail = JSON.parse(JSON.stringify(res.data.data[0]))
        this.detail.create_time = time
        if (ID.length > 10) {
          const nftID = ID.substring(0, 10) + '****' + ID.substring(ID.length - 10)
          this.detail.nftid = nftID
          this.detail.copyId = res.data.data[0].nftid
        }
      } catch (e) {
        console.log(e)
        this.isLoading = false
        this.$toast.fail(e.data)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.xz-detail{
  min-height: 100vh;
  background-color: black;
  overflow: hidden;
  .up{
    max-height: 666px;
    position: relative;
    .back {
      position: absolute;
      top: 22px;
      left: 18px;
      width: 27px;
      height: 27px;
      z-index: 9999;
    }
  }
  .arrow{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 58px;
    width: 100%;
    position: absolute;
    top: 500px;
    animation: move 1s linear infinite alternate;
    @keyframes move {
      0% {
        transform: translateY(0);
      }
      100% {
        transform: translateY(30px);
      }
    }
  }
  .content{
    padding: 30px 17px 40px;
    .item{
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
      color: white;
      align-items: center;
      .tip{
        background: #FFFFFF;
        border-radius: 3px;
        padding: 5px 10px;
        text-align: center;
        color: black;
        width: 65%;
      }
    }
  }
  .empty{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
}
</style>
